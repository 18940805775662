.MenuSection {
    border-bottom: 1px solid var(--blackLighten50LegacyColor);

    @media (--tablet) {
        border: none;
        padding-bottom: 40px;
    }
}

.MenuContent {
    z-index: 22;
}

.Link {
    @mixin onFocus {
        @mixin focusVisible 3px;
    }
}

.Variant--opened {
    z-index: 1070;

    & .Inner {
        opacity: 1;
        visibility: visible;
        transition-property: opacity, visibility;
        transition-delay: 0.4s;

        & button,
        & a {
            visibility: visible;
        }
    }

    &.Content {
        display: block;
        transform: translateY(var(--headerMobileHeight));
        transition:
            transform 0.4s ease-out 0s,
            box-shadow 0.1s 0.1s;
        width: 100vw;
        overflow-y: scroll;
        opacity: 1;

        @media (--tablet) {
            transform: translateY(var(--headerHeight));
        }
    }
}

.Variant--closed {
    & .Inner {
        @media (--mobileOnly) {
            visibility: visible;
        }
    }

    &.Content {
        transition:
            transform 0.4s ease-in 0.1s,
            opacity 0s 0.5s,
            box-shadow 0.1s;
    }
}

.Content {
    height: calc(100vh - var(--headerMobileHeight));
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    overflow-y: hidden;
    width: 100%;
    transform: translateY(-100%);
    background: var(--whiteBaseColor);
    z-index: 1070;
    opacity: 0;
    box-shadow: none;

    @media (--tablet) {
        padding-top: 40px;
        transform: translateY(-100%);
        padding-bottom: 24px;
        height: calc(100vh - var(--headerHeight));
    }
}

.Inner {
    @mixin wrapper;
    display: block;
    position: relative;
    transition: opacity 0.1s ease-out;
    transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    padding-bottom: 88px;
    min-height: 100%;

    @media (--tablet) {
        padding-bottom: 0;
        min-height: 0;
    }

    & button,
    & a {
        visibility: hidden;
    }
}

.Level1 {
    list-style: none;
    padding: 16px 0 0;
    margin: 0;

    @media (--tablet) {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        width: 100%;
    }

    & .MenuSection {
        @media (--tablet) {
            lost-column: 1/2 0 64px;

            &.MenuSection.MenuSection {
                margin-right: var(--gutterWidth);
            }
        }
    }
}

.Level1--threeColumnLayout {
    & .MenuSection {
        @media (--desktop) {
            lost-column: 1/3 0 48px;
        }
    }
}

.Level1--fourColumnLayout {
    & .MenuSection {
        @media (--desktop) {
            lost-column: 1/4 0 44px;
        }
    }
}

.Level1--fiveColumnLayout {
    & .MenuSection {
        @media (--desktop) {
            lost-column: 1/5 0 40px;
        }
    }
}

.Level2 {
    padding: 0;
    margin: 0;
    list-style: none;

    @media (--mobileOnly) {
        padding-bottom: 16px;
    }

    & > li {
        padding: 8px 0;
    }
}

.LangPicker {
    @media (--tablet) {
        border-top: 1px solid var(--blackLighten50LegacyColor);
    }
}

.NavLanguagePicker {
    display: inline-block;
    margin: 24px auto 12px 0;

    @media (--tablet) {
        display: none;
    }
}
