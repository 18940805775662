.List {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 16px;

    @media (--tablet) {
        padding-top: 40px;
        border-top: 1px solid var(--blackLighten50Color);
        margin: 0;
    }
}

.ListItem--active {
    & .ItemName {
        font-weight: 500;
        border-bottom: none !important;
    }

    & svg {
        fill: var(--primaryHKDarken20Color);
    }
}

.ItemLink {
    &:hover .ItemName {
        border-bottom-color: var(--primaryHKLighten40Color);
    }

    & .ItemIcon {
        margin-right: 16px;

        & svg {
            display: block;
            width: 24px;
            height: 24px;

            @media (--mobileOnly) {
                margin-right: 16px;
            }
        }
    }

    & .ItemName {
        border-bottom: 2px solid var(--primaryHKBaseColor);
    }

    &.ItemLink {
        display: flex;
    }
}

.ListItem {
    padding: 12px 0;

    @media (--tablet) {
        padding: 0;
        margin-top: 16px;
    }
}

.Content {
    display: flex;
    align-items: center;
}
