.Nav {
    @mixin hideOnPrint;
    display: flex;

    & .LangPicker {
        @media (--mobileOnly) {
            display: none;
        }
    }
}

.Variant--searchOpen {
    @media (--tablet) {
        & .LangPicker .Button,
        & .Login {
            & svg {
                margin-left: 0;
            }

            & span:first-child {
                display: none;
            }
        }
    }
}

.ButtonList {
    @mixin clearList;
    display: flex;
    margin-right: -16px;
}

.LinkList {
    @mixin clearList;
    display: none;

    @media (--tablet) {
        display: flex;
        margin-right: 24px;
    }
}

.ListItem {
    @media (--tablet) {
        margin: 0 0 0 8px;
    }

    &:first-child {
        margin: 0;
    }
}
