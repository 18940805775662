.List {
    @mixin clearList;
    display: flex;
    margin-right: -16px;
}

.Item {
    @media (--tablet) {
        margin: 0 0 0 8px;
    }

    &:first-child {
        margin: 0;
    }
}
