.InfoBanner {
    @mixin hideOnPrint;
    width: 100%;
    position: relative;
    height: var(--infoBannerMobileHeight);
    background-color: var(--blueDarken40LegacyColor);
    z-index: 1080;

    @media (--tablet) {
        height: var(--infoBannerHeight);
    }
}

.Inner {
    @mixin wrapper;
    @mixin p40;
    color: var(--whiteBaseColor);
    font-weight: 400;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (--tablet) {
        @mixin p30;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
    }

    & p {
        margin: 0;
    }

    & > * {
        padding-right: 32px;
    }
}

.Close {
    @mixin resetButtonStyles;
    position: absolute;
    height: 32px;
    width: 32px;
    right: 32px;
    top: calc(50% - 16px);
    pointer-events: all;

    @media (--tablet) {
        right: 24px;
    }

    & .CloseIcon {
        position: absolute;
        left: 15%;
        top: 15%;
        width: 70%;
        height: 70%;
        fill: var(--whiteBaseColor);

        & svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}
