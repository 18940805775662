@define-mixin navItemsColor $color {
    &.Button {
        color: $color;
    }

    &.ButtonIcon {
        & svg {
            fill: $color;
        }
    }
}

@define-mixin navButtonBase {
    @mixin resetButtonStyles;

    @mixin onFocus {
        @mixin focusVisible;
    }
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 3;
    padding: 10px;

    @media (--tablet) {
        padding: 16px;
    }

    & span:first-child {
        display: block;
        height: 24px;
        white-space: nowrap;
        margin-right: 16px;

        @media (--mobileOnly) {
            @mixin visuallyHidden;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

@define-mixin navButtonIcon {
    @mixin navButtonBase;

    @media (--tablet) {
        &:hover {
            & svg {
                padding: 0;
            }
        }
    }

    & svg {
        display: block;
        height: 26px;
        width: 26px;
        padding: 2px;
        transition: padding 0.2s;
    }
}

@define-mixin navButton {
    @mixin navButtonBase;

    @media (--tablet) {
        &:hover {
            @mixin visuallyBold;
        }
    }
}
