.Header {
    @mixin topHideAnimation;
    display: flex;
    position: fixed;
    height: var(--headerMobileHeight);
    width: 100%;
    flex: 1;
    top: 0;
    background: var(--whiteBaseColor);
    z-index: 1070;

    @media (--tablet) {
        height: var(--headerHeight);
    }

    @media print {
        position: static;
        box-shadow: none !important;
    }
}

.Variant--transparent {
    background: transparent;
    transition:
        background 0.2s ease-out,
        top 0.4s ease-in-out;

    &.Variant--searchOpened {
        @media (--mobileOnly) {
            background: var(--whiteBaseColor);
        }
    }
}

.Variant--scrollLocked.Variant--transparent,
.Variant--sticky.Variant--transparent {
    background: var(--whiteBaseColor);
}

.Variant--sticky {
    box-shadow: 0 5px 5px 0 rgb(var(--blackBaseRgb) / 5%);

    @media (--firefox) {
        position: fixed;
    }
}

.Wrapper {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
}

.Inner {
    @mixin wrapper;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HomeLink {
    flex-shrink: 0;
    position: relative;
    z-index: 3;
}

.SkipLink {
    @mixin skipLink;
}

:global(.Header__containerWrapper) {
    margin-bottom: var(--headerMobileHeight);

    @media (--tablet) {
        margin-bottom: var(--headerHeight);
    }

    @media print {
        margin-bottom: 0 !important;
    }
}

:global(.InfoBanner--active) {
    & .Header {
        height: calc(var(--headerMobileHeight) + var(--infoBannerMobileHeight));
        flex-direction: column;

        @media (--tablet) {
            height: calc(var(--headerHeight) + var(--infoBannerHeight));
        }
    }

    & :global(.Header__containerWrapper) {
        margin-bottom: calc(var(--headerMobileHeight) + var(--infoBannerMobileHeight));

        @media (--tablet) {
            margin-bottom: calc(var(--headerHeight) + var(--infoBannerHeight));
        }
    }
}

.HeaderStickyLanguagePicker {
    @mixin hideOnPrint;
    display: none;
    border: 0;

    @media (--tablet) {
        display: block;
    }
}

:global($(hiddenHeaderClassSelector)) {
    & .Variant--sticky {
        top: calc(-1 * (var(--headerHeight) + var(--infoBannerMobileHeight)));

        @media (--tablet) {
            top: calc(-1 * (var(--headerHeight) + var(--infoBannerHeight)));
        }
    }
}
