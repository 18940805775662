:global(.InfoBanner--active) {
    & .StickyLanguagePicker {
        @media (--tablet) {
            top: var(--infoBannerHeight);
        }
    }
}

.Content {
    display: flex;
    align-items: center;
}

.Variant--inline {
    @media (--mobileOnly) {
        & .Label {
            @mixin p;
            color: var(--blackBaseLegacyColor);
            font-weight: 500;
            max-width: 100px;
            margin: 0;
        }

        & .Icon {
            @mixin fixedRatioSvgIcon 24px;
            margin-right: 8px;
        }
    }
}

.Variant--sticky {
    @media (--tablet) {
        @mixin topHideAnimation;
        position: fixed;
        top: 0;
        right: 0;
        height: 24px;
        background-color: var(--redDarken40LegacyColor);
        z-index: 5;
        padding: 5px 11px;
    }

    & .Content {
        flex-direction: row-reverse;
    }

    & .Label {
        @mixin caption;
        color: var(--whiteBaseColor);
        margin: 0 9px 0 0;
        overflow: hidden;
        max-width: 65px;
        transition: max-width 0.3s ease-out;
        padding: 0;
    }

    & .Icon {
        position: relative;
        width: 14px;
        height: 14px;

        & svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            fill: var(--whiteBaseColor);
        }
    }
}

.Variant--stickyNarrow {
    & .Label {
        max-width: 0;
        margin: 0;
    }
}
