.SearchButton {
    &::before {
        content: '';
        width: 100%;
        height: var(--headerMobileHeight);
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 2;
        background: var(--whiteBaseColor);
        overflow: hidden;

        @media (--mobileOnly) {
            height: 0;
            opacity: 0;
        }

        @media (--tablet) {
            display: none;
        }
    }

    & ::placeholder {
        @media (--tablet) {
            color: var(--blackBaseColor);
        }
    }
}

.NavigationButton {
    &.NavigationButton {
        @media (--tablet) {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 4;
        }
    }
}

.Container {
    @media (--tablet) {
        position: relative;
    }
}

.Dropdown {
    @media (--mobileOnly) {
        @mixin centerElements;
        width: 100%;
        position: absolute;
        left: 0;
        top: var(--headerMobileHeight);
        background: var(--blackLighten60LegacyColor);
        height: 96px;
        box-shadow: var(--boxShadow);
        transform: translateY(-100%);
        opacity: 0;
        z-index: -1;
    }

    @media (--tablet) {
        padding-top: 4px;
        position: relative;
        z-index: 3;
    }
}

.Variant--opened {
    &::before {
        height: var(--headerMobileHeight);

        @media (--tablet) {
            height: var(--headerHeight);
        }
    }

    & .Dropdown {
        @media (--mobileOnly) {
            transform: translateY(0);
            transition: transform 0.3s ease-out;
            opacity: 1;
        }
    }

    & .NavigationButton {
        @media (--tablet) {
            display: none;
        }
    }

    & .Input {
        &::placeholder {
            @media (--tablet) {
                color: var(--blackLighten30LegacyColor);
            }
        }

        @media (--tablet) {
            width: 200px;
            border-color: var(--blackLighten50Color);
            outline: none;
        }

        @media (--aboveWrapper) {
            width: 310px;
        }
    }
}

.Variant--closed {
    &::before {
        @media (--mobileOnly) {
            transition:
                height 0s linear 0.3s,
                opacity 0s linear 0.3s;
        }
    }

    & .Dropdown {
        @media (--mobileOnly) {
            transition:
                opacity 0s linear 0.3s,
                transform 0.3s ease-out;
        }
    }
}

.Inner {
    display: flex;
    position: relative;

    @media (--mobileOnly) {
        width: 100%;
        padding: 0 32px;
        justify-content: center;
    }
}

.SearchButton:not(.Variant--opened) .SearchButtonAction svg {
    display: none;
}

.SearchButtonAction {
    @mixin onFocus {
        @mixin focusVisible -4px;
    }
    box-shadow: none;
    height: 48px;
    width: 48px;
    border: none;
    background: none;
    position: absolute;
    right: 32px;
    top: 0;
    padding: 0;

    @media (--tablet) {
        right: 0;
    }

    & svg {
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 auto;
        fill: var(--blackBaseLegacyColor);
    }
}

.Input {
    width: 100%;
    height: 48px;
    border: 1px solid var(--blackLighten50LegacyColor);
    padding: 0 64px 0 16px;
    background: var(--whiteBaseColor);

    &::placeholder {
        @media (--tablet) {
            color: var(--blackBaseLegacyColor);
            opacity: 1;
        }
    }

    &::-ms-clear {
        display: none;
    }

    @media (--tablet) {
        width: 48px;
        padding-right: 40px;
        transition: all 0.3s ease-out;
        border-color: transparent;
        background: transparent;
    }
}

.Variant--light {
    & .Input {
        background-color: var(--whiteBaseColor);
    }
}
