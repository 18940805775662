.MenuSection {
    border-bottom: 1px solid var(--blackLighten50LegacyColor);

    @media (--tablet) {
        border: none;
        padding-bottom: 40px;
    }
}

.Heading {
    @mixin h4;
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
    min-height: 64px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border: none;
    justify-content: space-between;
    width: 100%;
    background: none;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    padding: 0 40px 0 0;

    @media (--tablet) {
        min-height: 0;
        margin-bottom: 16px;
        border: none;
        font-size: 20px;
        line-height: 28px;
        padding: 0 0 0 6px;
    }
}

.Button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;

    &::after {
        content: '';
        margin: 5px 6px 0 0;
        display: block;
        border-style: solid;
        border-width: 8px 6px 0;
        border-color: var(--blackBaseLegacyColor) transparent transparent transparent;
    }

    @media (--tablet) {
        display: none;
    }
}

.Variant--opened {
    & .Button {
        &::after {
            transform: rotate(180deg);
        }
    }
}

.ContentWrapper {
    @media (--mobileOnly) {
        height: 0;
        transition: height 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        overflow: hidden;
    }

    @media (--tablet) {
        height: auto !important;
        visibility: visible !important;
    }
}

.Content {
    @mixin linkContainerOverflowHidden;
}
