.Dot {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: -8px;
        right: -8px;
        width: 16px;
        height: 16px;
        background-color: var(--blueBaseLegacyColor);
        border-radius: 50%;
        border: solid 2px transparent;
        background-clip: padding-box;
    }

    & svg {
        mask: url('/Static/Images/shape.svg');
    }
}
