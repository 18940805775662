.ButtonIcon {
    @mixin navButtonIcon;
}

.Button {
    @mixin navButton;
}

.Variant--light {
    @mixin navItemsColor var(--whiteBaseColor);
}

.Variant--dark {
    @mixin navItemsColor var(--blackBaseColor);
}
