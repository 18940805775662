.Button {
    @mixin navButtonIcon;
    z-index: 2;
}

.Variant--opened {
    z-index: 1070;
}

.Variant--closed {
    /* todo. Move here from main styles (where this variant is defined as default). */
}
